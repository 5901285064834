<template>
  <div class="page">
    <div class="dkzc-box">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/index-mockExam' }"
          >模拟卷</el-breadcrumb-item
        >
        <el-breadcrumb-item>试卷</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="heade">
        <span class="countdown">倒计时</span>
        <!-- <span class="countdown">59:59</span> -->
        <Countdown
          :time="detail.total_minute * 60"
          format="hh:mm:ss"
          @on-end="finish"
        >
          <template slot-scope="{ time }">{{ time }}</template>
        </Countdown>
      </div>
      <!-- 二屏 -->
      <div class="testPaper" ref="wrap">
        <div class="left">
          <span class="title">试卷说明</span>
          <div class="left-two">
            <span>总题量：</span>
            <span class="two-number">{{ detail.item_count }}题</span>
            <span>考试时间：</span>
            <span class="two-number">{{ detail.total_minute }}分钟</span>
            <span>总分值：</span>
            <span class="two-number">{{ detail.total_score }}分</span>
          </div>
          <!-- <span class="prompt">选择题（试卷题型分为单选题和多选题，请根据题型选择您认为正确的答案）</span> -->
          <span class="prompt"
            >选择题（试卷题型为单选题，请根据题型选择您认为正确的答案）</span
          >
          <!-- 左侧题 -->
          <div
            class="left-three"
            v-for="(item, index) in detail.question"
            :key="index"
            :id="'answerItem' + (index + 1)"
          >
            <span v-html="index + 1 + '. ' + item.question.caption">
              （<span v-html="item.question.item_type == 6 ? '多' : '单'"></span
              >选题）
            </span>

            <!-- <el-checkbox-group v-if="item.question.item_type==6">
                            <el-checkbox :label="item1.id" v-model="item1.checked" v-for="(item1, index1) in item.question.tk_option" :key="index1"></el-checkbox>
                        </el-checkbox-group> -->
            <div class="three-question">
              <el-checkbox-group
                v-if="item.question.item_type == 6"
                v-model="item.question.check"
                @change="checkboxGroupChange($event, index)"
              >
                <el-checkbox
                  :label="item1.id"
                  v-for="item1 in item.question.tk_option"
                  :key="item1.id"
                >
                  <span v-html="item1.text"> </span>
                </el-checkbox>
              </el-checkbox-group>
              <el-radio-group v-model="item.question.check" v-else>
                <el-radio
                  :label="item1.id"
                  v-for="item1 in item.question.tk_option"
                  :key="item1.id"
                >
                  <span v-html="item1.text"> </span
                ></el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <!-- 右侧答题卡 -->
        <div class="right" ref="right">
          <span class="right-title">答题卡</span>
          <div class="right-two">
            <div class="green"></div>
            <span>已答</span>
            <div class="green"></div>
            <span>未答</span>
          </div>
          <div class="right-three">
            <div
              class="question"
              :class="{
                active:
                  item.question.check.length ||
                  String(item.question.check).length,
              }"
              v-for="(item, index) in detail.question"
              :key="index"
              @click="getLocation(index + 1)"
            >
              <span>{{ index + 1 }}</span>
            </div>
          </div>
        </div>
      </div>
      <span class="button" @click="sure">交卷</span>
    </div>
    <el-backtop :right="450">答题卡</el-backtop>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <span class="dialogVisibleTitle"
        >试卷共{{ detail.item_count }}道题，还有{{
          num
        }}道题没答您确定要交卷吗？</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" v-if="showFlag"
          >取 消</el-button
        >
        <el-button type="primary" @click="sure1" class="button"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Countdown from "@choujiaojiao/vue2-countdown";
export default {
  components: { Countdown },
  data() {
    return {
      detail: {},
      answer: [],
      request: true,
      dialogVisible: false,
      num: 0,
      showFlag: true,
    };
  },
  async mounted() {
    // serial_code
    this.xin.isLoadding(true);
    await this.getExamInfo(this.$route.query.serial_code);
    this.xin.isLoadding(false);
  },
  methods: {
    finish() {
      this.dialogVisible = true;
      this.showFlag = false;
      this.sure();
    },
    async sure1() {
      if (this.request) {
        this.xin.isLoadding(true);
        this.request = false;
        let res = await this.$newsApi.addExam({
          serial_code: this.$route.query.serial_code,
          answer: JSON.stringify(this.answer),
        });
        this.request = true;
        if (res.code == 200) {
          this.xin.message("提交成功");
          setTimeout(() => {
            //                         // this.xin.backPage()
            this.xin.goUrl(
              `index-mockExam-simulationResult?serial_code=${this.$route.query.serial_code}&exam_id=${res.data}`
            );
            // this.xin.goUrl(`personalCenter?index=4`)
            // this.$router.push({ path: '/index-mockExam-simulationResult',query:{serial_code:item.serial_code,exam_id:item.id} })
          }, 1500);
        } else {
          this.xin.message(res.msg);
        }
        this.xin.isLoadding(false);
      }
    },
    async sure() {
      this.num = 0;
      let answer = {};
      for (let i in this.detail.question) {
        answer[this.detail.question[i].question.id] =
          this.detail.question[i].question.check;
        if (this.detail.question[i].question.check == "") {
          this.num += 1;
        }
      }
      this.dialogVisible = true;
      this.answer = answer;
    },
    getLocation(index) {
      let id = "answerItem" + index;
      window.scrollTo({
        top:
          document.getElementById(id).offsetTop +
          this.$refs.wrap.offsetTop -
          15,
        behavior: "smooth",
      });
    },
    async getExamInfo(serial_code) {
      let res = await this.$newsApi.getExamInfo({
        serial_code: serial_code,
      });
      if (res.code == 200) {
        let data = res.data;
        for (let i in data.question) {
          data.question[i].question.check = "";
          if (data.question[i].question.item_type == 6) {
            data.question[i].question.check = [];
            let arr = data.question[i].question.tk_option;
            for (let j in arr) {
              arr[j].checked = false;
            }
          }
        }
        this.detail = data;
        // this.timestamp = this.detail.total_minute * 60
      } else {
        this.xin.Toast(res.message);
      }
    },
    // 选中任一checkbox时，由checkbox-group触发
    checkboxGroupChange(e, index) {
      if (e.length) {
        this.detail.question[index].question.check = e;
      } else {
        this.detail.question[index].question.check = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.page {
  .button {
    background: $--themeColor;
    border: 1px solid $--themeColor;
  }
  .dialogVisibleTitle {
    padding: 20px 20px 30px 20px;
    margin: 0 auto;
    // padding-bottom: 30px;
    font-size: 18px;
    line-height: 26px;
  }
  ::v-deep .el-checkbox__label {
    white-space: normal;
    word-break: break-all;
  }
  ::v-deep .el-radio {
    margin-top: 10px;
  }
  ::v-deep .el-checkbox {
    margin-top: 10px;
  }
  ::v-deep .el-radio__label {
    white-space: normal;
    word-break: break-all;
  }
  ::v-deep .el-backtop {
    width: 100px;
    color: #fff;
    border-radius: 5px;
    background: $--themeColor;
  }
  background: #f2f2f2;
  // 添加颜色类
  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: $--themeColor !important;
  }
  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    background: $--themeColor !important;
    border-color: $--themeColor !important;
  }
  ::v-deep .el-radio__inner:hover {
    border-color: $--themeColor !important;
  }
  > .dkzc-box {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: 0 auto;
    > .heade {
      margin-top: -45px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #8f8f8f;
      font-size: 16px;
      margin-bottom: 42px;
      > .title {
        font-size: 18px;
        color: #333333;
        font-weight: 600;
        margin-right: 10px;
      }
      > .big {
        margin: 0 10px;
      }
      > .countdown {
        &:last-child {
          margin-left: 0;
        }
        font-size: 18px;
        color: #ff8307;
        margin-left: 374px;
      }
    }
    > .testPaper {
      display: flex;
      flex-direction: row;
      > .left {
        display: flex;
        flex-direction: column;
        width: 850px;
        background: #ffffff;
        padding: 30px;
        box-sizing: border-box;
        font-size: 18px;
        color: #333333;
        > .title {
          font-size: 24px;
          color: #333333;
          margin-bottom: 10px;
        }
        > .left-two {
          font-size: 14px;
          margin-bottom: 10px;
          > .two-number {
            margin-right: 60px;
          }
        }
        > .prompt {
          margin-bottom: 30px;
        }
        > .left-three {
          > span {
            line-height: 25px;
          }
          display: flex;
          flex-direction: column;
          margin-top: 40px;
          &:nth-child(4) {
            margin-top: 0;
          }
          > .three-question {
            margin-top: 19px;
          }
        }
      }
      > .right {
        display: flex;
        flex-direction: column;
        width: 310px;
        background: #ffffff;
        padding: 30px 20px;
        box-sizing: border-box;
        margin-left: auto;
        height: max-content; //包裹自己的元素
        > .right-title {
          font-size: 24px;
          color: #333333;
        }
        > .right-two {
          display: flex;
          flex-direction: row;
          margin: 19px 0;
          font-size: 12px;
          > .green {
            &:nth-child(3) {
              background: #ffffff;
              border: 1px solid #333333;
              margin-left: 15px;
            }
            width: 12px;
            height: 12px;
            background: #dbfbf8;
            border: 1px solid $--themeColor;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
        > .right-three {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .question {
            cursor: pointer;
            width: calc(100% / 6);
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.active {
              > span {
                color: $--themeColor;
                background: #dbfbf8;
                border: 1px solid $--themeColor;
              }
            }
            > span {
              width: 30px;
              height: 30px;
              background: white;
              border: 1px solid #333333;
              border-radius: 50%;
              font-size: 16px;
              color: #333333;
              text-align: center;
              line-height: 30px;
            }
          }
        }
      }
    }
    > .button {
      cursor: pointer;
      width: 170px;
      height: 50px;
      background: $--themeColor;
      border: 1px solid $--themeColor;
      border-radius: 26px;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
      line-height: 50px;
      margin-top: 40px;
      margin-left: 340px;
    }
  }
}
</style>
